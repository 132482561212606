import React from "react";
import { Link } from "gatsby";
import { Button, Col, Container, Nav, Row } from "react-bootstrap";
import LogoAltSvg from "../inline_svg/logo-alt.svg";

const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center text-lg-start py-8">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs="12" lg className="mb-6 mb-lg-0">
            <LogoAltSvg />
            <p className="fs-5 fw-lighter mt-4 mb-0">
              &copy; 2021 bld.ai. All rights reserved
            </p>
          </Col>
          <Col xs="12" lg="auto" className="mb-6 mb-lg-0">
            <Nav className="justify-content-center">
              <Nav.Link as={Link} to="/" className="fs-2 fw-light text-primary">
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/privacy-policy/"
                className="fs-2 fw-light text-primary"
              >
                Privacy Policy
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/#faqs"
                className="fs-2 fw-light text-primary"
              >
                FAQs
              </Nav.Link>
            </Nav>
          </Col>
          <Col xs="12" lg="auto">
            <Button
              as={Link}
              to="/apply/"
              size="lg"
              variant="primary"
              className="text-white"
            >
              Apply Now
            </Button>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
